// import React from 'react'
// import { Card as CardComponent,Button } from 'theme-ui'
// import { Link } from 'gatsby'
// import { Layout, Stack, Main, Sidebar } from '@layout'
// import CardList from '@components/CardList'
// import Card from '@components/Card'
// import Divider from '@components/Divider'
// import Seo from '@widgets/Seo'
// import AuthorExpanded from '@widgets/AuthorExpanded'
// import NewsletterCompact from '@widgets/NewsletterCompact'
// import TableOfContentsExpanded from '@widgets/TableOfContentsExpanded'
// import {
//   PostImage,
//   PostBody,
//   PostComments,
//   PostCommentsFacebook,
//   PostCommentsGraph,
//   PostTagsShare
// } from '@widgets/Post'



// const Post = ({
//   data: { post, tagCategoryPosts, tagPosts, categoryPosts, previous, next },
//   ...props
// }) => {
//   const relatedPosts = [
//     ...(tagCategoryPosts ? tagCategoryPosts.nodes : []),
//     ...(tagPosts ? tagPosts.nodes : []),
//     ...(categoryPosts ? categoryPosts.nodes : [])
//   ]
//   const { pageContext: { services = {}, siteUrl } = {} } = props

//   return (
//     <Layout {...props}>
//       <Seo {...post} siteUrl={siteUrl} />
//       <Divider />
//       <Stack effectProps={{ effect: 'fadeInDown' }}>
//         <Main>
//           <Card {...post} variant='horizontal-hero' omitExcerpt omitMedia />
//         </Main>
//         {services.mailchimp && (
//           <Sidebar pl={[null, 2, 4, 5]}>
//             <NewsletterCompact omitTitle />
//           </Sidebar>
//         )}
//       </Stack>
//       <Divider space={3} />
//       <Stack effectProps={{ fraction: 0 }}>
//         <Main>
//           {post.tableOfContents?.items && (
//             <>
//               <TableOfContentsExpanded {...post} />
//               <Divider />
//             </>
//           )}
//           <CardComponent variant='paper-lg'>
//             <PostImage {...post} inCard />
//             <PostBody {...post} />
//             <Button variant='primary' as={Link} to='https://wa.me/6285218289639?text=Halo Admin, Saya mau konsultasi'>
//             Jasa Olah Data, Hubungi Sekarang
//             </Button>
//             <PostTagsShare {...post} location={props.location} />
//             <PostComments/>
//             {services.graphComment && <PostCommentsGraph {...post} />}
//             {services.facebookComment && (<PostCommentsFacebook {...post} siteUrl={siteUrl} />
//             )}
//           </CardComponent>
//           <Divider />
//           <AuthorExpanded author={post.author} />
//           <Divider />
//           {post.category && (
//             <CardList
//               title='Related Pros'
//               nodes={relatedPosts}
//               variant={['horizontal-md']}
//               columns={[1, 2, 2, 2]}
//               limit={6}
//               distinct
//             />
//           )}
//         </Main>
//       </Stack>
//     </Layout>
//   )
// }

// export default Post

import React from 'react'
import { Card as CardComponent,Button } from 'theme-ui'
import { Link } from 'gatsby'
import { Layout, Stack, Main, Sidebar } from '@layout'
import CardList from '@components/CardList'
import Divider from '@components/Divider'
import Sticky from '@components/Sticky'
import Seo from '@widgets/Seo'
import AuthorExpanded from '@widgets/AuthorExpanded'
import TableOfContentsCompact from '@widgets/TableOfContentsCompact'
import {
  PostHead,
  PostImage,
  PostBody,
  PostComments,
  PostTagsShare,
  PostFooter
} from '@widgets/Post'

const Post = ({
  data: { post, tagCategoryPosts, tagPosts, categoryPosts, previous, next },
  ...props
}) => {
  const relatedPosts = [
    ...(tagCategoryPosts ? tagCategoryPosts.nodes : []),
    ...(tagPosts ? tagPosts.nodes : []),
    ...(categoryPosts ? categoryPosts.nodes : [])
  ]
  const { pageContext: { services = {}, siteUrl } = {} } = props

  return (
    <Layout {...props}>
      <Seo {...post} siteUrl={siteUrl} />
      <Divider />
      
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PostHead {...post} />
      </Stack>
      <Divider />
      <Stack effectProps={{ fraction: 0 }}>
        <Main>
        <Button variant='primary' as={Link} to='https://wa.me/6285218289639?text=Halo Admin, Saya mau konsultasi statistik'>
              Punya masalah analisis data?   Konsultasikan Sekarang
        </Button>
        <Divider />
          <CardComponent variant='paper'>
            <PostImage {...post} inCard />
            <PostBody {...post} />
            <Button variant='primary' as={Link} to='https://wa.me/6285218289639?text=Halo Admin, Saya mau konsultasi statistik'>
              Punya masalah analisis data?   Konsultasikan Sekarang
            </Button>
            <PostTagsShare {...post} location={props.location} />
            <PostComments />
            <PostFooter {...{ previous, next }} />
          </CardComponent>
        </Main>
        <Sidebar>
          <Divider />
          <Sticky>
            {post.tableOfContents?.items && (
              <>
                <TableOfContentsCompact {...post} />
                <Divider />
              </>
            )}
            {post.category && (
              <CardList
                title='Related Posts'
                nodes={relatedPosts}
                variant='horizontal-aside'
                limit={6}
                omitMedia
                omitCategory
                distinct
                aside
              />
            )}
          </Sticky>
        </Sidebar>
      </Stack>
    </Layout>
  )
}

export default Post
